:root {
    --main-color: rgb(217, 227, 241);
    --main-border-color: rgb(161, 188, 228);
    --main-font-blue: rgb(1, 4, 98);
}

/* Browser Reset */

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font-family: tahoma, arial, verdana, sans-serif;
    vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
    display: block;
}

body {
    line-height: 1;
}

/*
ol, ul {
    list-style: none;
}
*/
blockquote, q {
    quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
    content: '';
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

h1 {
    font-size: 160%;
    margin: 1em 0 0.5em 0;
}

small {
    font-size: 70%;
    font-weight: normal;
}

/* End of Browser Reset */

html,
body,
#root {
    margin: 0;
    padding: 0;
    height: 100%;
}

.fgs-container {
    height: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
}

.fgs-top-panel {
    display: flex;
    flex-direction: column;
    height: 80px;
    padding: 0 20px;
    border-bottom: 1px solid var(--main-border-color);
    color: var(--main-font-blue);
    background-color: var(--main-color);
}

.fgs-top-panel h1 {
    float: left;
    width: 500px;
}

.fgs-top-panel .top-panel-version {
    font-size: 50%;
    font-weight: normal;
    margin-left: 2em;
}

.fgs-top-panel .user {
    width: 400px;
    position: absolute;
    top: 20px;
    right: 20px;
}

.fgs-top-panel .user .username {
    text-align: right;
}

.fgs-top-panel .user .logout {
    float: right;
}

.fgs-content-panel {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
}

.fgs-bottom-panel {
    display: flex;
    flex-direction: column;
    height: 30px;
    justify-content: center;
    text-align: center;
    border-top: 1px solid var(--main-border-color);
    color: var(--main-font-blue);
    background-color: var(--main-color);
}

.fgs-content-innerpanel {
    display: flex;
    flex-direction: row;
    height: 100%;
}

.fgs-main-panel {
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: hidden;
}

.fgs-tab-control {
    height: 100%;
    padding: 20px 20px 10px 20px;
    overflow: hidden;
}

.fgs-left-panel {
    order: -1;
    display: flex;
    flex-direction: column;
    width: 250px;
    overflow: auto;
    border-right: 3px solid var(--main-border-color);
}

.fgs-auftrag-footer {
    flex: 0 0 130px;
    border-top: 1px solid var(--main-border-color);
}

.panel-titlebar .btn {
    margin: 5px 15px;
    border: 1px solid silver;
    border-radius: 3px;
}

.fgs-left-panel .inner-panel {
    padding: 20px 1em;
}

.react-tabs__tab-panel {
    height: 100%;
}

.fgs-tab-panel {
    flex-direction: row;
    height: 100%;
}

.fgs-auftraege-list,
.fgs-invoice-list,
.fgs-statistics-list,
.fgs-fee-list {
    flex: 0 0 auto;
    height: 100%;
    overflow: scroll;
}

.fgs-auftraege-panel, .fgs-airline-panel {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.fgs-auftraege-list {
    display: flex;
    flex: 1;
    border-top: 1px solid silver;
    /*height: 80%;*/
}

.fgs-auftraege-list .refnr {
    background-color: #FFFF99;
}

.fgs-auftraege-list .summe {
    background-color: #BBFFBB;
    font-weight: bold;
}

table.fixedHead thead th {
    border-top: none;
    position: sticky;
    font-weight: bold;
    color: #666;
    top: 0;
}

.fgs-auftraege-form {
    flex: 0 0 130px;
}

.loading {
    position: absolute;
    background-color: #666666;
    display: block;
    opacity: 0.5;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    color: #FFFFFF;
    z-index: 10000;
}

.loading-spinner {
    position: relative;
    top: 50%;
    left: 50%;
}

.azr-list {
    height: 6em;
    overflow-x: scroll;
}

.airlines-list {
    max-height: 600px;
    overflow-x: scroll;
    border: 1px solid blue;
}

.azr-details table {
    border-collapse: collapse;
}

.azr-details th, .azr-details td {
    border: none;
    text-align: left;
}

.azr-details th {
    font-weight: normal;
}

.azr-details td {
    font-weight: bold;
}

table {
    border-collapse: collapse;
}

th, td {
    border: 1px solid silver;
    padding: 2px 5px;
    font-size: 90%;
}

th {
    background-color: rgb(240, 240, 240);
    font-weight: normal;
}

#loginform {
    width: 500px;
    height: 30px;
    margin: auto;
    padding-top: 150px;

}

#loginform .form-inner {
    border: 1px solid darkblue;
    border-radius: 5px;
    width: 100%;
    padding: 0 0 2em 0;
}

#loginform h2 {
    background: darkblue;
    color: white;
    text-align: center;
    height: 50px;
    line-height: 50px;
    margin-bottom: 0.5em;
}

#loginform .form-subtitle {
    margin-bottom: 2em;
    text-align: center;
    color: #666666;
    font-size: 90%;
    font-weight: bold;
}

#loginform .form-versionnumber {
    margin: 3em 0 0 3em;
    text-align: left;
    color: #999999;
    font-size: 90%;
}

#loginform .form-group {
    margin-top: 1em;
}

#loginform label {
    display: block;
    text-align: center;
    margin-bottom: 3px;
    color: #666666;
    font-size: 75%;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
}

#loginform input[type="email"], #loginform input[type="password"] {
    display: block;
    margin: auto;
    width: 80%;
    height: 1.8em;
    text-align: center;
    font-size: 90%;
    color: #444444;
    background-color: white;
    border: 1px solid silver;
    font-weight: bold;
}

#loginform input[type="submit"] {
    display: block;
    margin: 4em auto 0;
    width: 80%;
    height: 40px;
    background-color: white;
    font-size: 90%;
    font-weight: bold;
    color: darkblue;
    text-align: center;
    border: 1px solid darkblue;
    text-transform: uppercase;
    letter-spacing: 1px;
}

#loginform input[type="submit"]:hover {
    background-color: darkblue;
    color: white;
    cursor: pointer;
}

#loginform .login-error {
    width: 80%;
    margin: 2em auto 0;
    border: 1px solid darkred;
    padding: 0.5em 0;
    background-color: darkred;
    color: white;
    text-align: center;
}

.form-title {
    color: var(--main-font-blue);
    background-color: var(--main-color);
    font-size: 80%;
    font-weight: bold;
    height: 20px;
    padding: 5px 0 0 10px;
    border-bottom: 1px solid var(--main-border-color);
}

.form-edit .form-title {
    background-color: #fff79c;
}

.fgs-kontrollstreifen {
    position: absolute;
    right: 30px;
}


.fgs-auftrag-form .form-item {
    display: block;
    width: fit-content;
    width: -moz-fit-content;
    float: left;
}

.fgs-auftrag-form .edit-auftrag-id {
    color: silver;
    font-weight: normal;
    position: absolute;
    right: 30px;
}

.fgs-auftrag-form .form-body {
    padding: 10px 0 0 1em;
}

.fgs-auftrag-form label,
.fgs-auftrag-form input {
    width: inherit;
}

.sammelNachttaxi-field input,
.direkt-field input,
.post-field input,
.nebenkosten-field input,
.porto-field input,
.summe-field input
{
    text-align: right;
}

.fgs-auftrag-form label {
    font-size: 80%;
    color: #666666;
}

.autoporto-true input{
    background-color: #bbffbb;
}

.autoporto-false input {
    background-color: #ffff99;
}


.form-item input {
    border: 1px solid silver;
}

.form-item input, .form-item select {
    font-family: tahoma, arial, verdana, sans-serif;
    font-size: 110%;
    color: #333;
    font-weight: bold;
}

.refnr-field input {
    background-color: #ffff99;
}

.summe-field input {
    background-color: #bbffbb;
}

td.betrag, .ag-cell.betrag {
    text-align: right;
}

.btn {
    background-color: rgb(161,188,228);
    border: none;
    color: #FFFFFF;
    font-size: 16px;
    cursor: pointer;
}

.btn:hover {
    background-color: DodgerBlue;
}

.react-tabs__tab {
    border: 2px solid transparent !important;
    border-bottom: none !important;
    bottom: -2px !important;
    color: var(--main-font-blue) !important;
    font-weight: bold;
}

.react-tabs__tab--selected {
    border-color: var(--main-border-color) !important;
}

.react-tabs__tab-list {
    border-bottom: 2px solid var(--main-border-color) !important;
}

.ag-cell, .ag-header-cell {
    padding: 0 3px !important;
}

.ag-body-viewport {
    overflow-y: scroll !important;
}

.fgs-airline-form {
    position: relative;
    margin-left: 10px;
    border: 1px solid var(--main-border-color);
}

.fgs-airline-form .form-body {
    padding: 0 10px;
}

.fgs-airline-form label {
    display: block;
    line-height: 180%;
    color: silver;
}

.fgs-airline-form input, .fgs-airline-form select {
    position: absolute;
    left: 150px;
    width: 250px;
    font-size: 100%;
}

fieldset {
    border: 1px solid var(--main-border-color);
    padding: 5px 10px;
    border-radius: 5px;
    margin-bottom: 10px;
}

legend {
    padding: 0 5px;
    font-size: 80%;
    color: var(--main-font-blue);
}

/*
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #000000;
}

.App-link {
  color: #09d3ac;
}
*/
